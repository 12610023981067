const utilsFontSizeClass = (fontSize, lgFontSize) => {
  const fontSizes = {
    tiny: 'text-tiny',
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl',
    '2xl': 'text-2xl'
  }

  return `${fontSizes[fontSize]} lg:${fontSizes[lgFontSize]}`
}

const utilsTextColorClass = (textColor) => {
  return textColor
    ? {
        white: 'text-white',
        black: 'text-black',
        'light-gray': 'text-light-gray',
        'dark-gray': 'text-dark-gray',
        'green-gray': 'text-green-gray',
        red: 'text-red'
      }[textColor]
    : {}
}

const utilsLayoutClass = (index, self) => {
  const breakpoints = ['sm', 'md', 'lg']
  const classes = []

  breakpoints.map((breakpoint) => {
    const layout = self[`${breakpoint}Layout`]
    const bpCols = self[`${breakpoint}Cols`]
    const bp = breakpoint === 'sm'
      ? ''
      : breakpoint === 'lg'
        ? 'lg:'
        : breakpoint + ':'

    // This is just kinda nasty, but we're figuring out what each
    // breakpoint's column span should be, based on the layout
    // and amount of columns.

    // evenly share the columns out
    if (layout === 'even') {
      return classes.push(`${bp}col-span-${Math.round(12 / bpCols)}`) // 12 cols / breakpoint columns
    }

    // Split layouts
    if (layout === '2-1') {
      return index === 0 ? classes.push(`${bp}col-span-8`) : classes.push(`${bp}col-span-4`)
    }

    // if (layout === '1-2') {
    // }
    return index === 0 ? classes.push(`${bp}col-span-4`) : classes.push(`${bp}col-span-8`)
  })

  return classes.join(' ')
}

export {
  utilsFontSizeClass,
  utilsTextColorClass,
  utilsLayoutClass
}
