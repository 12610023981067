import ButtonLink from '~/components/made-button/ButtonLink.vue'
import { utilsFontSizeClass } from '@/utils/computed-classes'

export default {
  name: 'MadeButton',
  components: {
    ButtonLink
  },
  props: {
    fontSize: {
      type: String,
      default: 'xl',
      required: false
    },
    lgFontSize: {
      type: String,
      default: '2xl',
      required: false
    },
    variant: {
      type: String,
      required: false,
      default: 'underline'
    },

    alignment: {
      type: String,
      required: false,
      default: 'self-start'
    },

    label: {
      type: String,
      required: false,
      default: () => ('')
    },

    letterSpacing: {
      type: String,
      required: false,
      default: () => ('')
    },

    link: {
      type: [Object, String],
      required: false,
      default: () => ({})
    },

    targetBlank: {
      type: Boolean,
      required: false,
      default: () => (false)
    },

    /**
     * Makes it possible to force use a link from a parent,
     * i.e. a Hero.
     */
    inheritedLink: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  computed: {
    fontSizeClass () {
      return utilsFontSizeClass(this.fontSize, this.lgFontSize)
    },
    buttonLink () {
      return this.inheritedLink?.id ? this.inheritedLink : this.link
    },

    variantClass () {
      return {
        'text-white': this.variant === 'underline-white',
        'text-black': this.variant === 'underline-black',
        'bg-transparent border border-white px-6 py-2 text-white leading-0 hover:border-border-gray hover:text-border-gray': this.variant === 'outline-white',
        'bg-transparent border border-black px-6 py-2 text-black leading-0 hover:border-black-gray hover:text-black-gray': this.variant === 'outline-black'
      }
    },
  }
}
